
import './App.css';
import {useState} from "react";
import {sourceData} from "./sourceData";
import {ToolItem} from "./tool_item/ToolItem";
// import bootstrap from 'bootstrap';

function App() {
    const [keyWords,setKeyWords]=useState("");

    function getFilteredList(){
        const keywords=keyWords.split(" ");
        let filteredList=sourceData.tools;
        keywords.forEach((keyword)=>{
           filteredList=filteredList.filter((tool)=>{
               let digest=tool.name+" "+tool.type+" "+tool.description+" "+(tool.keywords?tool.keywords:"");
               // console.log(digest);
               return digest.toLowerCase().includes(keyword.toLowerCase());
           }) ;
        });
        filteredList=filteredList.sort(
            (a,b)=>a.name[0]>b.name[0]?1:-1
        );
        return filteredList;

    }

  return (
    <>
        <header className={'header'}>
            <h1>
                <center>X Online Tools</center>
            </h1>
        </header>
        <div className={"container"}>
                <div className="input-group search-bar">
                    <input className="form-control py-2 rounded-pill mr-1 pr-5" type="search"
                           defaultValue={'search tools'}
                           onFocus={(e)=>{e.target.value=keyWords}}
                           onChange={(e)=>setKeyWords(e.target.value)}
                    />
                </div>
            <div
                // className={"list-group  table"}
            >
                {
                    getFilteredList().length!==0?
                        getFilteredList().map((tool,index)=><ToolItem key={index} tool={tool}/>):
                        <div className={" no-result"}>
                            <span >No Result Found</span>
                        </div>
                }
            </div>
        </div>
    </>
  );
}

export default App;
