export const sourceData={
    tools:[
        {
            name:"PDF Converter",
            link:"https://www.freepdfconvert.com/",
            types:["Pdf"],
            description:"Support many pdf transform operations",
            limitation:"Free use 3 daily",
        },
        {
            name:"Y2Mate",
            link:"https://en.y2mate.is/86/youtube-to-mp3.html",
            types:["Youtube"],
            description:"Youtube extractor; Download youtube as mp4 or mp3",
            limitation:"",
        },
        {
            name:"Windfinder",
            link:"https://www.windfinder.com/",
            types:["Weather","Wind"],
            description:"Provide info about wind(weather)",
            limitation:"",
        },
        {
            name:"30dayweather",
            link:"https://www.30dayweather.com/en/",
            types:["Weather"],
            description:"Provide weather forecast within 30 days",
            limitation:"",
        },
        {
            name:"X-rates",
            link:"https://www.x-rates.com/",
            types:["Currency"],
            description:"Provide real-time or historical exchange rates between any currency",
            limitation:"",
        },
        {
            name:"Receive Sms",
            link:"https://www.receivesms.org/",
            types:["Sms service"],
            description:"Allow you to receive sms via a temporary phone number( good for using to register)",
            limitation:"",
            keywords:"message",
        },
        {
            name:"AURORA FORECAST",
            link:"https://www.gi.alaska.edu/monitors/aurora-forecast",
            types:["Weather"],
            description:"Provide aurora forecast within 27 days",
            limitation:"",
        },
        {
            name:"Symbolab",
            link:"https://www.symbolab.com/",
            types:["Calculator"],
            description:"Can calculate math formula reuslt and provide calculation procedures  ",
            limitation:"",
        },
        {
            name:"Free Formatter",
            link:"https://www.freeformatter.com/",
            types:["Program develop","Json"],
            description: `**Formattor**: JSON, XML, HTML, SQL...\n **Converter**: JSON<->XML, CSV->XML/JSON, YAML<->JSON...\n**Encode/Decode**:Url,Base64...\nOther:Code minifier/Beautifier, String Escape, ...`,
            limitation:"",
            keywords: "json xml html sql"
        },
        {
            name:"Free Formatter-XPath Test",
            link:"https://www.freeformatter.com/xpath-tester.html",
            types:["Program develop","XPath"],
            description: "XPath Tester",
            limitation:"",
            keywords: "XPath test validator"
        },
        {
            name:"Online-Convert",
            link:"https://www.online-convert.com/",
            types:["File"],
            description:"Files Converter: Audio, Video, Image, Documents, Ebooks, Zip files, PDF, Word etc",
            limitation:"",
        },
        {
            name:"Image Color Picker",
            link:"https://imagecolorpicker.com/en",
            types:["Image"],
            description:"Image color picker",
            limitation:"",
        },
        {
            name:"BMI Calculator",
            link:"https://www.calculator.net/bmi-calculator.html",
            types:["Health"],
            description:"BMI calculator ",
            limitation:"",
        },
        {
            name:"The World Clock",
            link:"https://www.timeanddate.com/worldclock/",
            types:["Clock","Earth"],
            description:"World clocks",
            keywords:"world clock",
        },
        {
            name:"Coding Ground",
            link:"https://www.tutorialspoint.com/codingground.htm",
            types:["Program develop"],
            description:"**Online terminals**: Linux, Python, Ruby, Nodejs ... \n**light dev environment**: Python, Java, C, C++, PHP, Javascript, Perl, HTML5, Vuejs ...\n**Document Editors**: Markdown, Latex ...",
            keywords:"online terminal dev environment ",
        },
        { name:"Credit card number validation and generator",
            link:"https://neapay.com/online-tools/credit-card-number-generator-validator.html",
            types:["Credit Card"],
            description:"Credit card number validation and generator, also other tools about Credit Card",
            keywords:"Credit card number validation generator",
        },
        { name:"Convert Javascript Object to JSON",
            link:"https://www.convertsimple.com/convert-javascript-to-json/",
            types:["Program develop"],
            description:"Convert Javascript Object/dic to JSON",
            keywords:"Js object dict JSON",
        },
        { name:"Convert JSON to Javascript Object",
            link:"https://www.convertsimple.com/convert-json-to-javascript/",
            types:["Program develop"],
            description:"Convert Javascript Object/dict to JSON",
            keywords:"Js object dict JSON",
        },
        { name:"Regex Testing - Javascript, Pcre",
            link:"https://www.regextester.com/",
            types:["Program develop"],
            description:"Dynamically show your regex expression's  matching result for your test string",
            keywords:"Regex match Js Nodejs",
        },
        { name:"I Love Image",
            link:"https://www.iloveimg.com/",
            types:["Image"],
            description:"Image Resize,Crop,Compress,Edit",
            keywords:"cut size edit",
        },
    ]
}