import './ToolItem.css'
import ReactMarkdown from 'react-markdown'
import {typeIcon } from '../lib/type_icon'

export function ToolItem({tool}){
    return (
        <div className={" tool-item"}>
            <div className={"tool-title"}  >
                <a className={" tool-name "} href={tool.link} target={"_blank"}>
                    <h2>{tool.name}</h2>
                </a>
                <div className={" tool-type"}>

                    {tool.types&&tool.types.map((type)=>
                        <div className={"tool-icon"}>{typeIcon[type]}</div>
                    )}
                    {/*<div className={"tool-icon"}>{typeIcon[tool.types]}</div>*/}
                    {/*{tool.type}*/}
                </div>
            </div>
            <hr className={'header-line'}/>
            <div>
                {
                    tool.description&&
                    <div style={{whiteSpace: "pre-line"}}><ReactMarkdown>{tool.description}</ReactMarkdown></div>
                }
            </div>
            <div>
                {
                    tool.limitation&&
                    <div>Limit: {tool.limitation}</div>
                }
            </div>
        </div>
        // <div className={'card'}>
        //     <div className="card-header " style={{background:"white"}}>
        //         <div className={"tool-title"}  >
        //              <a className={" tool-name "} href={tool.link} target={"_blank"}>
        //                  {tool.name}
        //              </a>
        //              <div className={" tool-type"}>
        //              <div className={"tool-icon"}>{typeIcon[tool.type]}</div>
        //                   {tool.type}
        //              </div>
        //         </div>
        //     </div>
        //     <div className="card-body">
        //         <div>
        //             {
        //                 tool.description&&
        //                 <div>{tool.description}</div>
        //             }
        //         </div>
        //         <>
        //             {
        //                 tool.limitation&&
        //                 <div>Limit: {tool.limitation}</div>
        //             }
        //         </>
        //         {/*<h5 className="card-title">Special title treatment</h5>*/}
        // {/*        /!*<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>*!/*/}
        // {/*        /!*<a href="#" className="btn btn-primary">Go somewhere</a>*!/*/}
        // {/*    </div>*/}
        // {/*</div>*/}
    );
}